.abtgrid {
    text-align: left;
    margin-top: 20px;   
}
.abtgd1 {
    color: rgb(7, 127, 197);
    margin-top: 20px;
}
.Company {
    text-align: center;
         color: rgb(6, 200, 248);
}