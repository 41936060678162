.foot {
    background-color: rgb(2, 8, 44);
    text-align: left;
    color: white;
}
.f1 {
    color: rgb(231, 218, 38);
}
.fff {
    padding-top: 40px;
    padding-bottom: 40px;
}