.header{
    background-color: rgb(2, 8, 44);
    /* position: fixed;
    z-index: 1;
    width: 100%; */
}
.mr-auto{
    color:black
}
.nav-links{
display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    text-transform: uppercase;
margin-left: 500px;
width: 70%;
}
.search{
    display: flex;
}
.logo img{
    height: 60px;
    width: 90px;
    
}
.mr-auto{
    padding: auto;
}
.input{
    margin-right: 10px;
}
.form-control{
    border-radius: 1.25rem;
}

.menu:hover{
    background-color:transparent;

}
.menu {
    color: rgb(231, 218, 38);
}
