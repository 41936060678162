.Success {
    margin-top: 20px;
    margin-bottom: 30px;
    color: rgb(6, 200, 248);
    text-align: center;
}
.accordian {
    margin-top: 30px;
    text-align: left;
}
