.cfm {
    color: rgb(7, 127, 197);
margin-bottom: 30px;
text-align: center;
padding-top: 50px;
}
.gform {
    width: 30%;
    margin-left: 400px;    
padding-bottom: 50px;
}
.bkpic{
    background-image: url('../Assests/Images/bkgpic.jpg');
    height: 100%;
}