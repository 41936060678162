.sty {
    text-align: left;
    color: rgb(7, 127, 197);

}
.texts {
    margin-top: 30px;
}
.sty1{
    text-align: left;
}
.txtgrd {
    margin-top: 30px;
}
.hing{
    color: rgb(6, 200, 248);
}
