.Ourservice {
    color: rgb(6, 200, 248);
    margin-top: 30px;
}
.frame {
	text-align: center;	
	position: relative;
	cursor: pointer;	
	perspective: 500px; 
    margin-top: 30px;
}
.frame img {
	width: 300px;
	height: 300px;
}
.frame .details {
	width: 70%;
	height: 80%;	
	padding: 5% 8%;
	position: absolute;
	content: "";
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotateY(90deg);
	transform-origin: 50%;
	background: rgba(255,255,255,0.9);	
	opacity: 0;
	transition: all 0.4s ease-in;
	
}
.frame:hover .details {
	transform: translate(-50%, -50%) rotateY(0deg);
	opacity: 1;
}