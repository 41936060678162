.abt img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }
  .abt:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    
  }
.About{
    position: relative;
}
.abttext{
position: absolute;
top: 50%;
left: 30%;
text-align: left;
color: grey;
}
.About:hover .abttext {
    transform: translate(-50%, -50%) rotateY(0deg);
    opacity: 1;
    transition: 0.5s;
}
.About .abttext{
    opacity: 0;
}
 .st {
     color: rgb(252, 174, 6);
     font-family:italian ;
     
 }
 .Target{
     color: grey;
 }
 